/**
* app/shared_core/scss/shared_core = defaults fuse and matérial styles ( don't touche )
* app/themes/theme = Special theming variables
Jeux de variables appelé pour crée un theme particulier tel que Fuse (il gère la grande majorité des styles)
* app/themes/_variables-custom = Nos ajouts de variables particulières
* app/themes/shared_core/shared_core = index to concat folder's files
*/

@import "app/themes/inclusive/main";
@import "~latex-keyboard/katex/katex-modified.min.css";

// https://fonts.googleapis.com/icon?family=Material+Icons content
/* fallback */
/* Use local font instead of googlefonts due to GAR restrictions */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: font('material-icons.woff2') format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

@import 'ckeditor5/ckeditor5.css';

.ckeditor-main-container {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    .ck-content {
        line-height: 1.6;
        word-break: break-word;
    }

    .editor-container_classic-editor .editor-container__editor {
        min-width: 795px;
        max-width: 795px;
    }
}
